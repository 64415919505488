<template>
  <div>
    <loading :is-show="loadingDialog" :is-done="loadingFinished" :is-html="isHtml" :message="loadingMessage"
             v-on:close-dialog="closeDialog"/>
    <details-form :is-show="isShowDetails" :details="details" v-on:close-dialog="closeDialog" v-on:update-status="updateStatus" v-on:send-sale="sendSale" v-on:send-reversal="sendReversal"/>
    <v-container class="container--fluid grid-list-md text-left" :style="{ fontSize: '15px',  color: '#465a75' }">
            <v-icon :style="{ color: '#465a75',paddingBottom: '11px', marginRight: '5px' }" left-bottom x-large>mdi-cash-multiple</v-icon> Payconnect Transactions <v-text class="text-h5 font-weight-bold"> ({{totalReports}}) </v-text>
    </v-container>
    <v-card outlined :style="{ border: '1px solid #dedede', marginBottom: '20px', marginLeft: '10px', marginRight: '10px'}" shaped tile class="pa-1">
        <div class="d-flex flex-no-wrap justify-space-between">
            <v-card-title>
                <v-container>
                    <v-layout row wrap>
                        <v-row no-gutters>
                            <v-col md lg="1"></v-col>
                            <v-col cols="12" md = "3" lg :style="{ marginTop: '20px' }">
                                <v-text> Search By: </v-text>
                            </v-col>
                            <v-col cols="12" md = "3"  lg>
                                 <v-text-field class="ma-2" v-model ="query.payconnectReference" label="Payconnect Reference #" single-line outlined @change="searchPayConnectRef" data-cy="module-search-payconnect-reference"></v-text-field>
                            </v-col>
                            <v-col cols="12" md = "3" lg>
                                <v-autocomplete
                                    clearable
                                    class="ma-2"
                                    :items="merchantList"
                                    item-text="name"
                                    item-value="merchantId"
                                    label="Merchant"
                                    data-cy="module-merchant-id"
                                    v-model="selectedMerchant"
                                    return-object
                                    single-line outlined
                                    @change="searchMerchant">
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" md = "3" lg>
                                 <v-select class="ma-2" v-model ="query.paymentStatus" single-line outlined :items="paymentStatus" label="Payment Status" @change="searchStatus" data-cy="module-search-status"></v-select>
                            </v-col>
                            <v-col cols="12" md = "3" lg>
                                <v-text-field v-model ="query.merchantReferenceNo" single-line outlined class="ma-2" label="Merchant Reference #" @change="searchMerchantRef" data-cy="module-search-merchant-reference"></v-text-field>
                            </v-col>
                            <v-col></v-col>
                        </v-row>
                    </v-layout>
                </v-container>
                <v-container>
                    <v-layout row wrap>
                        <v-row no-gutters>
                            <v-col></v-col>
                            <v-col cols="12" md="3" lg :style="{ marginTop: '20px' }">
                                <v-text> Date Created From: </v-text>
                            </v-col>
                            <v-col cols="12" md="3" lg>
                                <v-menu v-model="fromDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="300px" min-width="300px" data-cy="date-menu-from">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field class="ma-2" single-line outlined label="Date Created From" append-icon="mdi-calendar" readonly :value="fromDateDisp" v-on="on" data-cy="date-menu-from"></v-text-field>
                                    </template>
                                    <v-date-picker locale="en-in" v-model="fromDateVal" no-title @input="fromDateMenu = false" :min="minDate" :max="fromMax" data-cy="date-menu-from"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="3" lg :style="{ marginTop: '20px' }">
                                <v-text> Date Created To: </v-text>
                            </v-col>
                            <v-col cols="12" md="3" lg >
                                <v-menu ref="menu" v-model="toDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="300px" min-width="300px" data-cy="date-menu-to">
                                <template v-slot:activator="{ on }">
                                    <v-text-field class="ma-2" single-line outlined label="Date Created To" append-icon="mdi-calendar" readonly :value="toDateDisp" v-on="on" data-cy="date-menu-to"></v-text-field>
                                </template>
                                <v-date-picker locale="en-in" v-model="toDateVal" no-title @input="toDateMenu = false" :max="maxDate" :min="toMin" data-cy="date-menu-to">
                                    <v-btn text color="primary" @click="clearToDate()" data-cy="button-clear">Clear</v-btn>
                                </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" md="3" lg :style="{ marginTop: '2px' }">
                                <v-btn class="ma-2" x-large color="#1d6499" :style="{ color: 'white' }" @click="queryDataV2Csv()" :disabled="disableCsvExportButton" data-cy="button-export-search">
                                    <v-icon large :style="{ color: '#8eb0ca' }" left> mdi-table-arrow-down</v-icon> Search AND Export
                                </v-btn>
                            </v-col>
                            <v-col cols="12" md="3" lg :style="{ marginTop: '2px' }">
                                <v-btn class="ma-2" x-large color="#1d6499" :style="{ color: 'white' }" @click="queryDataV2()" data-cy="button-search">
                                    <v-icon large :style="{ color: '#8eb0ca' }" left> mdi-magnify</v-icon> Search
                                </v-btn>
                            </v-col>
                            <v-col cols="12" md="3" lg :style="{ marginTop: '2px' }">
                                <v-btn class="ma-2" x-large color="#1d6499" :style="{ color: 'white' }" @click="resetInputs()" data-cy="button-search">
                                    <v-icon large :style="{ color: '#8eb0ca' }" left> mdi-history </v-icon> Reset
                                </v-btn>
                            </v-col>
                            <v-col>
                            </v-col>
                        </v-row>
                    </v-layout>
                </v-container>
            </v-card-title>
        </div>
    </v-card>

    <v-data-table :hide-default-header="true"
                  :headers="headers" 
                  :options.sync="options"
                  :loading="loading"
                  :items="displayReports"
                  :page="page"
                  :pageCount="numberOfPages"
                  :server-items-length="totalReports"
                  :style="{ border: '1px solid #dedede', marginBottom: '20px', marginLeft: '10px', marginRight: '10px'}"
                  :footer-props="{'items-per-page-options': [10, 20, 50]}">
    <template v-slot:header="{ props: { headers } }">
        <thead>
            <tr :colspan="headers.length">
                <th v-for="header in headers" :key="header.text" :style="{ backgroundColor: '#dfedf8', fontWeight: 'bold', fontSize: '12px', color: 'black'}">
                    <span class="d-flex justify-center">{{header.text}}</span>
                </th>
            </tr>
        </thead>
    </template>
      <template v-slot:body="{ items }">
        <tbody>
        <tr :colspan="headers.length" v-for="item in items" :key="item.id">
          <td><span class="d-flex justify-center"> {{ item.payconnectReference }} </span></td>
          <td><span class="d-flex justify-center"> <pre>{{ item.amount }} </pre></span></td>
          <!-- <td> <span class="d-flex justify-center"> {{ item.isEnabled ? 'ENABLED' : 'DISABLED' }} </span></td> -->
          <td><span class="d-flex justify-center"> {{ item.paymentStatus }} </span></td>
          <td><span class="d-flex justify-center"> {{ item.reasonCode }} </span></td>
          <td><span class="d-flex justify-center"> {{ item.merchantReferenceNo }} </span></td>
          <td><span class="d-flex justify-center"> {{ item.merchantName }} </span></td>
          <td><span class="d-flex justify-center"> {{ item.billerName }} </span></td>
          <td><span class="d-flex justify-center"> {{ item.dateCreated }} </span></td>
          <td>
            <span class="d-flex justify-center">
                <v-btn fab dark x-small color="#00a8e8" class="mr-2" @click.stop="viewTransaction(item)">
                    <v-icon dark medium>mdi-magnify-plus</v-icon>
                </v-btn>
                <!-- <v-btn fab dark x-small color="green" @click.stop="showThresholdForm('UPDATE', item)">
                    <v-icon dark medium>mdi-pencil</v-icon>
                </v-btn> -->
            </span>
          </td>
        </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {
    mapGetters
} from 'vuex';
import SwitchAPI from '@/api/SwitchAPI'
import Loading from '@/views/components/Loading.vue'
import DetailsForm from '@/views/components/TransactionForm.vue'
import moment from 'moment';

export default {
    name: 'Transactions',
    components: {
        Loading,
        DetailsForm
    },
    computed: {
        ...mapGetters([
            'attributes',
            'authorities'
        ]),
        fromDateDisp() {
            this.toMin = moment().format(this.fromDateVal)
            return this.fromDateVal;
        },
        toDateDisp() {
            this.fromMax = moment().format(this.toDateVal)
            return this.toDateVal;
        },
    },
    data() {
        return {
            disableCsvExportButton: true,
            paymentStatus: ['', 'PAID', 'PENDING', 'POSTED', 'EXPIRED', 'STIP', 'FOR VALIDATION', 'DECLINED'],
            headers: [],
            isNotMerchant: false,
            records: [],
            merchants: [],
            merchantList: this.merchants ?? [],
            loading: true,
            selectedMerchant: {},
            selectedStatus: '',
            payConnectRef: '',
            merchantRef: '',
            page: 1,
            totalReports: 0,
            numberOfPages: 0,
            query: {
                payconnectReference: '',
                paymentStatus: '',
                merchantReferenceNo: '',
            },
            options: {
                page: 1,
                itemsPerPage: 10,
            },
            details: {
                title: '',
                item: {}
            },
            fromDateMenu: false,
            fromDateVal: "",
            toDateMenu: false,
            toDateVal: "",
            minDate: "1997-01-01",
            maxDate: "",
            fromMax: "",
            toMin: "",
            offset: 0,
            limit: 0,
            displayReports: [],
            remainderReports: [],
            loadingMessage: "",
            loadingDialog: false,
            loadingFinished: false,
            isShowDetails: false,
            showFormDialog: false,
            isHtml: false,

        }
    },
    async mounted() {
        this.setDefaultDateRange();
        this.setHeaders();
        await this.setData();
    },
    watch: {
        options: {
            handler() {
                this.queryDataV2();
            },
            deep: true,
        },
        merchants: {
            handler(value) {
                this.merchantList = value
            }
        },
    },
    methods: {
        hasPermission(authority) {
            if (this.authorities.some(x => x.name === authority)) {
                return true
            }
            return false
        },
        async setData() {
            try {
                const listResponse = await SwitchAPI.getUserMerchants()
                if (!listResponse || listResponse.error) {
                    //error getting data
                    console.log(`${listResponse.error}`)
                } else {
                    this.merchants = listResponse.merchants
                }
            } catch (e) {
                console.log(e)
            }
        },
        async queryDataV2() {
            this.loading = true;
            const parameters = {
                offset: this.options.page - 1,
                limit: this.options.itemsPerPage,
                merchantID: this.selectedMerchant ? this.selectedMerchant.merchantId ?? '' : '',
                payID: this.payConnectRef,
                merchantReferenceNo: this.merchantRef,
                paymentStatus: this.selectedStatus,
                dateFrom: this.fromDateVal,
                dateTo: this.toDateVal
            }
            const transactionList = await SwitchAPI.filterTransactionV2(parameters)
            this.totalReports = transactionList.totalRows;
            this.displayReports = transactionList.transactions;
            this.disableCsvExportButton = this.totalReports < 1;
            this.loading = false
        },
        async queryDataV2Csv() {
            await this.queryDataV2();
            this.loadingDialog = true
            this.isHtml = true
            this.loadingFinished = false
            this.loadingMessage = `Downloading...`
            const parameters = {
                offset: this.options.page - 1,
                limit: this.options.itemsPerPage,
                merchantID: this.selectedMerchant ? this.selectedMerchant.merchantId ?? '' : '',
                payID: this.payConnectRef,
                merchantReferenceNo: this.merchantRef,
                paymentStatus: this.selectedStatus,
                dateFrom: this.fromDateVal,
                dateTo: this.toDateVal
            }
            const downloadResponse = await SwitchAPI.filterTransactionV2Csv(parameters);
            this.loadingFinished = true
            if (!downloadResponse || downloadResponse.error) {
                this.isHtml = false
                this.loadingMessage = downloadResponse ? downloadResponse.error : "Error downloading file."
            } else {
                this.loadingMessage = `Download complete.`
            }
        },
        setHeaders() {
            var headers = [
                {
                    text: 'Reference Number',
                    value: 'payconnectReference',
                    sortable: false,
                },
                {
                    text: 'Amount',
                    value: 'amount',
                    sortable: false,
                },
                {
                    text: 'Payment Status',
                    value: 'paymentStatus',
                    sortable: false,
                },
                {
                    text: 'Reason Code',
                    value: 'reasonCode',
                    sortable: false,
                },
                {
                    text: 'Merchant Ref. Num.',
                    value: 'merchantReferenceNo',
                    sortable: false,
                },
                {
                    text: 'Merchant',
                    value: 'merchantName',
                    sortable: false,
                },
                {
                    text: 'Biller',
                    value: 'billerName',
                    sortable: false,
                },
                {
                    text: 'Date Created',
                    value: 'dateCreated',
                    sortable: false,
                    align: 'center',
                },
                {
                    text: 'Actions',
                    value: 'controls',
                    sortable: false,
                },
            ]
            this.headers = headers 
        },
        // deprecate
        async queryData(isSearch = false) {
            this.loading = true
            if (isSearch) {
                this.options.page = 1
            }
            const {
                page,
                itemsPerPage
            } = this.options;
            let pageNumber = page - 1;
            try {
                var transactionList = undefined

                const parameters = {
                    offset: pageNumber,
                    limit: itemsPerPage,
                    merchantID: this.selectedMerchant ? this.selectedMerchant.merchantId ?? '' : '',
                    payID: this.payConnectRef,
                    merchantReferenceNo: this.merchantRef,
                    paymentStatus: this.selectedStatus,
                    dateFrom: this.fromDateVal,
                    dateTo: this.toDateVal
                }
                transactionList = await SwitchAPI.filterTransaction(parameters)
                if (!transactionList || transactionList.error) {
                    //error getting data
                    console.log(`${transactionList.error}`)
                } else {
                    var filteredList = transactionList.transactions
                    this.isNotMerchant = transactionList.isNotMerchant
                    if (page > 1 && this.remainderReports.length > 0) {
                        filteredList = this.remainderReports.concat(filteredList)
                    }
                    this.totalReports = page > 1 ? (pageNumber * itemsPerPage) + filteredList.length : filteredList.length
                    const end = filteredList.length < itemsPerPage ? filteredList.length : itemsPerPage
                    this.displayReports = filteredList.slice(0, end)
                    this.remainderReports = filteredList.length < itemsPerPage ? [] : filteredList.slice(end)
                    this.numberOfPages = this.totalReports / itemsPerPage
                }
            } catch (e) {
                console.log(e)
            }
            this.loading = false
        },
        //method to update the Merchant query
        searchStatus: async function (val) {
            this.selectedStatus = val
            // this.queryData(true)
        },
        //method to update the Merchant query
        searchMerchant: async function (val) {
            this.selectedMerchant = val
            // this.queryData(true)
        },
        searchPayConnectRef(val) {
            this.payConnectRef = val
            // this.queryData(true)
        },
        searchMerchantRef(val) {
            this.merchantRef = val
            // this.queryData(true)
        },
        setDefaultDateRange() {
            this.fromDateVal = moment().subtract(1, "day").format("yyyy-MM-DD");
            this.toDateVal = moment().format("yyyy-MM-DD");
            this.maxDate = moment().add(1, "days").format("yyyy-MM-DD")
            this.fromMax = moment().add(1, "days").format("yyyy-MM-DD")
            this.toMin = moment().format(this.fromDateVal)
        },
        clearToDate() {
            this.toDateVal = ''
            this.toDateMenu = false
        },
        closeDialog(type) {
            if (type === 'details') {
                this.details.title = ''
                this.details.item = {}
                this.isShowDetails = false
            } else if (type === 'CREATED' || type === 'UPDATE') {
                this.showFormDialog = false
            } else if (type === 'loading') {
                this.loadingDialog = false
                // this.queryData();
            } else if (type === 'loading-error') {
                this.loadingDialog = false
            }
        },
        viewTransaction(transaction) {
            console.log(transaction)
            this.details.title = transaction.payconnectReference
            this.details.merchantId = transaction.merchantId
            this.details.status = transaction.paymentStatus
            this.details.isStipMerchant = transaction.isStipMerchant
            this.details.item = transaction
            this.isShowDetails = true
        },
        async updateStatus(value) {
            console.log("value.payconnectReference : " + value.payconnectReference)
            console.log("value.status: " + value.status)
            this.loadingDialog = true
            this.isHtml = true
            this.loadingFinished = false
            if (value) {
                this.loadingMessage = `Updating transaction <strong><i>${value.payconnectReference}</i></strong>`
                setTimeout(async () => {
                    const createResponse = await SwitchAPI.updateTransactionStatus(value)
                    console.log(createResponse)
                    this.loadingFinished = true
                    if (!createResponse || createResponse.error) {
                        this.isHtml = false
                        this.loadingMessage = createResponse.error
                    } else {
                        this.loadingMessage = `Successfully updated transaction <strong><i>${value.payconnectReference}</i></strong>`
                        this.showFormDialog = false
                        // this.$refs.userForm.resetForm()
                    }
                }, 2000);
            } else {
                this.loadingMessage = "Error processing data."
                this.loadingFinished = true
            }
        },
        async sendSale(value) {
            console.log("sendSale: " + value)
            console.log("value.payconnectReference : " + value.payconnectReference)
            console.log("value.merchantId: " + value.merchantId)
            this.loadingDialog = true
            this.isHtml = true
            this.loadingFinished = false
            if (value) {
                this.loadingMessage = `Send Sales Advice: <strong><i>${value.payconnectReference}</i></strong>`
                setTimeout(async () => {
                    const createResponse = await SwitchAPI.sendSale(value)
                    console.log(createResponse)
                    this.loadingFinished = true
                    if (!createResponse || createResponse.error) {
                        this.isHtml = false
                        this.loadingMessage = createResponse.error
                    } else {
                        this.loadingMessage = `Successfully Sent Sales Advice <strong><i>${value.payconnectReference}</i></strong>`
                        this.showFormDialog = false
                        // this.$refs.userForm.resetForm()
                    }
                }, 2000);
            } else {
                this.loadingMessage = "Error processing data."
                this.loadingFinished = true
            }
        },

        async sendReversal(value) {
            console.log("sendReverse: " + value)
            console.log("value.payconnectReference : " + value.payconnectReference)
            console.log("value.merchantId: " + value.merchantId)
            this.loadingDialog = true
            this.isHtml = true
            this.loadingFinished = false
            if (value) {
                this.loadingMessage = `Send Reversal: <strong><i>${value.payconnectReference}</i></strong>`
                setTimeout(async () => {
                    const createResponse = await SwitchAPI.sendReversal(value)
                    console.log(createResponse)
                    this.loadingFinished = true
                    if (!createResponse || createResponse.error) {
                        this.isHtml = false
                        this.loadingMessage = createResponse.error
                    } else {
                        this.loadingMessage = `Successfully Sent Reversal <strong><i>${value.payconnectReference}</i></strong>`
                        this.showFormDialog = false
                        // this.$refs.userForm.resetForm()
                    }
                }, 2000);
            } else {
                this.loadingMessage = "Error processing data."
                this.loadingFinished = true
            }
        },
        resetInputs() {
            this.selectedMerchant = {};
            this.displayReports = [];
            this.query.payconnectReference = ''
            this.query.paymentStatus = ''
            this.query.merchantReferenceNo = ''
            this.totalReports = 0
        }
    }
};
</script>
